import { ModalHeader, ModalModalProps } from '@loveholidays/design-system';
import { useTranslation } from '@loveholidays/phrasebook';
import React, { Fragment } from 'react';

import { HotelDetailsModalContentProps } from '@Components/HotelDetails/HotelDetailsModalContent';
import { LoadableHotelDetailsModalContent } from '@Components/HotelDetails/LoadableHotelDetailsModalContent';
import { LoadableModal } from '@Components/Modal/LoadableModal';

export interface HotelDetailsModalProps
  extends HotelDetailsModalContentProps,
    Pick<ModalModalProps, 'onClose' | 'Actions' | 'data-id'> {}

export const HotelDetailsModal: React.FC<HotelDetailsModalProps> = ({
  masterId,
  seoUrl,
  selectedTabId,
  showCtas,
  onClose,
  Actions,
  'data-id': dataId,
  trackingAction,
}) => {
  const { t } = useTranslation();

  return (
    <LoadableModal
      onClose={onClose}
      show
      isFullScreen
      noPadding
      width="fullcontentmaxwidth"
      sx={{
        maxWidth: '1200px',
      }}
      Header={
        <ModalHeader
          layout="medium"
          label={t('hotel.modal.title')}
          onClose={onClose}
        />
      }
      Actions={<Fragment>{Actions as any}</Fragment>}
      data-id={dataId}
      Content={
        <LoadableHotelDetailsModalContent
          masterId={masterId}
          seoUrl={seoUrl}
          selectedTabId={selectedTabId}
          showCtas={showCtas}
          trackingAction={trackingAction}
        />
      }
    />
  );
};
